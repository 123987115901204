
import apiFetch from "@/utils/request";
import { AxiosResponse } from "axios";
import { Vue, Options } from "vue-class-component";
import { CloudStorage } from "../../types/index";

@Options({
  // Define component options here
  // props: {
  //   ...
  // },
})
class CloudStorageComponent extends Vue {
  cloudStorages: CloudStorage[] = [];
  loading: boolean = true;
  error: string = "";
  created() {
    this.getCloudStorage().then(() => {
      this.loading = false;
    });
  }
  async getCloudStorage() {
    try {
      const response: AxiosResponse<{ data: CloudStorage[] }> =
        await apiFetch.cloudStorage.getCloudStorageList();
      this.cloudStorages = response?.data?.data;
    } catch (err) {
      this.error = err.response.data.error.message;
    }
  }
  async deleteCloudStorage(id: number) {
    try {
      await apiFetch.cloudStorage.deleteCloudStorage(id);
      await this.getCloudStorage();
    } catch (err) {
      this.error = err.response.data.error.message;
    }
  }
}
export default CloudStorageComponent;
